import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "slug": "/blog/housing-buying-guide",
  "date": "2016-06-05",
  "title": "A buying guide for new house buyers",
  "isComplete": "true",
  "subtitle": "Interaction Designer, Housing 2016",
  "gradient": "linear-gradient(180deg, #E3D7FE 0%, #A084F5 100%)",
  "featuredImage": "housing-snapshot.png",
  "images": ["housing-sketch.jpg"]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Back in 2015, Housing was one of India's biggest online real estate platforms. The real estate industry is not very friendly to new house buyers, and that's what we at Housing set out to fix.`}</p>
    <h4>{`The problem`}</h4>
    <p>{`House buying is a complicated concept. There are so many unknowns when it comes to the nitty gritty of buying a house. Beyond that, users could not understand the terminology being used by the real-estate vendors.`}</p>
    <h4>{`My approach`}</h4>
    <p>{`At the beginning of the project, I was approached by the Product Manager who told me that they have come up with a 30 page document as the buying guide. Now that's a lot of information. Who has the time to read that in an app?`}</p>
    <p>{`This is what I came up with:`}</p>
    <ul>
      <li parentName="ul">{`I realized that we needed to go through all this information and divide it into different stages of house buying: Finding a House, Home Loans and Finance, Choosing a Home, and Tax Implications`}</li>
      <li parentName="ul">{`All of these would contain concise sub information regarding the relevant topics`}</li>
      <li parentName="ul">{`This would be aided by interactive illustrations and explanations.`}</li>
    </ul>
    <p>{`But while doing this I also wanted to give the user an easy way to navigate and a sense of accomplishment as they completed the buying guide.`}</p>
    <p>{`Here are some early ideas that I sketched out.`}</p>
    <div {...{
      "className": "blog-content-image-container"
    }}>{`
  `}<img parentName="div" {...{
        "src": "https://res.cloudinary.com/dzrqeyspq/image/upload/v1621782213/housing-sketch_ngyzmf.jpg",
        "className": "blog-content-image",
        "alt": "housing sketches"
      }}></img>
    </div>
    <div className="blog-content-image-container">
  <video className="blog-content-video" playsInline webkitPlaysinline="" poster="https://thumbs.gfycat.com/HideousFlamboyantAlaskajingle-mobile.jpg" autoPlay loop muted height="600px">
    <source src="https://thumbs.gfycat.com/HideousFlamboyantAlaskajingle-mobile.mp4" id="mobile-source" type="video/mp4" />
    <source id="webmSource" src="https://zippy.gfycat.com/HideousFlamboyantAlaskajingle.webm" type="video/webm" />
    <source id="mp4Source" src="https://zippy.gfycat.com/HideousFlamboyantAlaskajingle.mp4" type="video/mp4" />
  </video>
    </div>
    <p className="image-caption">An early prototype</p>
    <div className="blog-content-image-container">
  <video className="blog-content-video" playsInline webkitPlaysinline="" poster="https://thumbs.gfycat.com/RegalKindGoldenmantledgroundsquirrel-mobile.jpg" autoPlay loop muted height="600px">
    <source src="https://thumbs.gfycat.com/RegalKindGoldenmantledgroundsquirrel-mobile.mp4" id="mobile-source" type="video/mp4" />
    <source id="webmSource" src="https://zippy.gfycat.com/RegalKindGoldenmantledgroundsquirrel.webm" type="video/webm" />
    <source id="mp4Source" src="https://zippy.gfycat.com/RegalKindGoldenmantledgroundsquirrel.mp4" type="video/mp4" />
  </video>
    </div>
    <p className="image-caption">
  A prototype for explaining house area terminology
    </p>
    <h4>{`What was shipped 🚀`}</h4>
    <p>{`After my initial explorations of the UX, I shared my work with the incredibly talented `}<a parentName="p" {...{
        "href": "https://twitter.com/saptarshipr"
      }}>{`Saptarshi Prakash`}</a>{` who did some amazing visual design on top of the UX. Let's have a look with the final outcome after the visual cycle.`}</p>
    <div className="blog-content-image-container">
  <video className="blog-content-video" playsInline webkitPlaysinline="" poster="https://thumbs.gfycat.com/CavernousImmaterialAlaskanmalamute-mobile.jpg" autoPlay loop muted height="600px">
    <source src="https://thumbs.gfycat.com/CavernousImmaterialAlaskanmalamute-mobile.mp4" id="mobile-source" type="video/mp4" />
    <source id="webmSource" src="https://fat.gfycat.com/CavernousImmaterialAlaskanmalamute.webm" type="video/webm" />
    <source id="mp4Source" src="https://giant.gfycat.com/CavernousImmaterialAlaskanmalamute.mp4" type="video/mp4" />
  </video>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      