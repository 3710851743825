import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "slug": "/blog/omio-search",
  "date": "2018-10-05",
  "title": "Helping travelers find the right trip",
  "isComplete": "true",
  "subtitle": "Senior Product Designer, Omio 2018",
  "gradient": "linear-gradient(180deg, #FEDADA 0%, #FB8989 100%)",
  "featuredImage": "omio-search-snapshot.png",
  "images": ["omio-before.png", "omio-2.png", "omio-beforeafter.gif", "omio-4.png"]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`One of the most important things for users in finding the right trip was how the results were displayed after a search was performed on the product. When I joined the company we were in the process of user testing our search results page. At the same time there was a parallel initiative to make the website completely responsive. This meant taking the mobile design system and scaling it up to the desktop.`}</p>
    <h6>{`The problem`}</h6>
    <p>{`At Omio, the users were having trouble searching because
a) the search results had legibility issues
b) the return trip experience was really confusing because the search results for outbound and return journeys looked too similar`}</p>
    <h6>{`What the design looked like before I started`}</h6>
    <p>{`The design I started working with had the following problems:`}</p>
    <ul>
      <li parentName="ul">{`There was no way to tell if the price was for one-way or for round trip`}</li>
      <li parentName="ul">{`The number of changes in a trip were unclear`}</li>
      <li parentName="ul">{`Our users said that the information on the screen was too dense and difficult to read`}</li>
    </ul>
    <div {...{
      "className": "blog-content-image-container"
    }}>{`
  `}<img parentName="div" {...{
        "src": "https://res.cloudinary.com/dzrqeyspq/image/upload/v1621782212/omio-before_ileci3.png",
        "className": "blog-content-image",
        "alt": "What we had before"
      }}></img>
    </div>
    <h4>{`Exploring different possibilities 🔮`}</h4>
    <p>{`I started exploring different options based on this feedback from the users e.g. I tried adding a label that explained the price, I improved the tap affordance for the ‘changes’ button. All of this while also figuring out a responsive systematic structure for the cell. This ranged from our smallest supported screen size of 320px all the way up to 1920px on desktop.`}</p>
    <div {...{
      "className": "blog-content-image-container"
    }}>{`
  `}<img parentName="div" {...{
        "src": "https://res.cloudinary.com/dzrqeyspq/image/upload/v1621782416/omio-2_rgxga5.png",
        "className": "blog-content-image",
        "alt": "A few different options I explored"
      }}></img>
    </div>
    <p>{`This design was user tested via an HTML prototype that I developed with my colleague who also had a development background. A coded prototype helped us test more realistic interactions with live data. After testing this prototype, this design was rolled out to our users.`}</p>
    <video className="blog-content-video" id="video-scarytalkativeaustrianpinscher" alt="Screen Recording 2021-05-14 at 15.24.23 GIF" width="100%" autoPlay playsInline loop preload="auto" poster="https://thumbs.gfycat.com/ScaryTalkativeAustrianpinscher-mobile.jpg" tabIndex="-1" muted="muted">
  <source src="https://giant.gfycat.com/ScaryTalkativeAustrianpinscher.mp4" type="video/mp4" />
  <source src="https://thumbs.gfycat.com/ScaryTalkativeAustrianpinscher-mobile.mp4" type="video/mp4" />
    </video>
    <p className="image-caption">An early prototype built in HTML</p>
    <h4>{`What was shipped 🚀`}</h4>
    <undefined><div {...{
        "className": "blog-content-image-container"
      }}>{`
  `}<img parentName="div" {...{
          "src": "https://res.cloudinary.com/dzrqeyspq/image/upload/v1621782214/omio-beforeafter_tppsgw.gif",
          "className": "blog-content-image",
          "style": {
            "borderRadius": "16px"
          },
          "alt": "before/after"
        }}></img>
      </div>
      <p {...{
        "className": "image-caption"
      }}>{`A quick before/after`}</p></undefined>
    <undefined><div {...{
        "className": "blog-content-image-container"
      }}>{`
  `}<img parentName="div" {...{
          "src": "https://res.cloudinary.com/dzrqeyspq/image/upload/v1621782214/omio-4_qaqwqo.png",
          "className": "blog-content-image",
          "style": {
            "borderRadius": "16px"
          },
          "alt": "Scaling the design across all sizes"
        }}></img>
      </div>
      <p {...{
        "className": "image-caption"
      }}>{`
  A solution that scaled across all of our platforms
`}</p></undefined>
    <video className="blog-content-video" id="video-elasticjollyamericanbobtail" alt="Omio Desktop" width="100%" autoPlay playsInline preload="auto" poster="https://thumbs.gfycat.com/ElasticJollyAmericanbobtail-mobile.jpg" tabIndex="-1" muted="muted">
  <source src="https://giant.gfycat.com/ElasticJollyAmericanbobtail.mp4" type="video/mp4" />
  <source src="https://thumbs.gfycat.com/ElasticJollyAmericanbobtail-mobile.mp4" type="video/mp4" />
    </video>
    <p className="image-caption">Omio on desktop</p>
    <div className="blog-content-image-container">
  <video className="blog-content-video" autoPlay playsInline loop="true" height="600px" preload="auto" muted="muted">
    <source src="https://giant.gfycat.com/CaringSpanishBeaver.mp4" type="video/mp4" />
  </video>
    </div>
    <p className="image-caption">Omio on mobile</p>
    <h4>{`Impact 🍪`}</h4>
    <p>{`After shipping the new search result page and the round-trip interactions, we had an `}<strong parentName="p">{`⬆️8%`}</strong>{` increase in round-trip bookings and a `}<strong parentName="p">{`⬆️5%`}</strong>{` increase in overall bookings!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      