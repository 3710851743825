// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-housing-mdx": () => import("./../../../src/pages/housing.mdx" /* webpackChunkName: "component---src-pages-housing-mdx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-lisn-mdx": () => import("./../../../src/pages/lisn.mdx" /* webpackChunkName: "component---src-pages-lisn-mdx" */),
  "component---src-pages-post-1-mdx": () => import("./../../../src/pages/post-1.mdx" /* webpackChunkName: "component---src-pages-post-1-mdx" */),
  "component---src-pages-post-2-mdx": () => import("./../../../src/pages/post-2.mdx" /* webpackChunkName: "component---src-pages-post-2-mdx" */),
  "component---src-pages-post-3-mdx": () => import("./../../../src/pages/post-3.mdx" /* webpackChunkName: "component---src-pages-post-3-mdx" */),
  "component---src-templates-blog-template-js": () => import("./../../../src/templates/blogTemplate.js" /* webpackChunkName: "component---src-templates-blog-template-js" */)
}

