import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "slug": "/blog/lisn",
  "date": "2016-10-05",
  "title": "Real-time synced music sharing with friends",
  "isComplete": "true",
  "subtitle": "Co-founder, Lisn 2016",
  "gradient": "linear-gradient(180deg, #F9DEB2 0%, #E6940D 100%)",
  "featuredImage": "lisn-snapshot.png",
  "images": ["lisn-icons.png", "lisn-icon.png"]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`I started working on Lisn in January 2016 with cofounders Vibhas Jain, Abhinav Chhikara, and Bhargav Sosale. We all had a mutual fondness for discovering what was the latest and greatest music online and wanted to share it with friends whenever we came across something we enjoyed.`}</p>
    <p>{`One of the best feelings that I got was when I found good music and shared it with a friend and how we would get hyped about it together <3 There's something really amazing about listening and discovering music with your friends. With the advent of realtime sharing apps such as Periscope and Houseparty, we thought that sharing the moment with music would be a great idea.`}</p>
    <p>{`We started out with a basic MVP of the product which we launched in April 2016. Our idea was to just have a place to chat and a library where you can revisit tracks that were sent to you by friends.`}</p>
    <p>{`Here's one of our early demos,`}</p>
    <iframe src="https://fast.wistia.net/embed/iframe/spfz80bmpr" allowtransparency="true" frameBorder="0" scrolling="no" className="wistia_embed" name="wistia_embed" allowFullScreen="allowfullscreen" mozallowfullscreen="mozallowfullscreen" webkitallowfullscreen="webkitallowfullscreen" oallowfullscreen="oallowfullscreen" msallowfullscreen="msallowfullscreen" width="100%" height="504" style={{
      "margin": "32px auto"
    }}></iframe>
    <p>{`We got a lot of feedback from the MVP and started thinking about what a finished product would look like. Initially, we thought that the music experience could be split into three parts: Discovery, Archiving, and Re-visiting. With that in mind, we created 3 basic tabs in the app, one for chat, one for the library, and the last for a solo player in the app to revisit music you've already shared with friends.`}</p>
    <p>{`One of our initial interactions was to make sharing tracks you've already received with friends super easy to re-share with just a tap:`}</p>
    <div className="blog-content-image-container">
  <video className="blog-content-video" height="600px" playsInline webkitPlaysinline="" poster="https://thumbs.gfycat.com/AccomplishedThirstyGar-poster.jpg" loop autoPlay muted>
    <source id="webmSource" src="https://zippy.gfycat.com/AccomplishedThirstyGar.webm" type="video/webm" />
    <source id="mp4Source" src="https://zippy.gfycat.com/AccomplishedThirstyGar.mp4" type="video/mp4" />
  </video>
    </div>
    <p className="image-caption">Just tap on the artwork to share</p>
    <p>{`With all of this baked in, we launched Lisn in August 2016. And lo and behold! We were `}<a parentName="p" {...{
        "href": "https://www.producthunt.com/posts/lisn"
      }}>{`number 3 on Product Hunt`}</a>{` and that led us to be `}<a parentName="p" {...{
        "href": "https://techcrunch.com/2016/08/23/lisns-new-app-lets-you-stream-songs-for-your-friends-while-chatting/"
      }}>{`featured on Tech Crunch!`}</a>{` Woohoo!`}</p>
    <h4>{`Branding`}</h4>
    <p>{`Right before launch I took on the task of figuring out what our app icon would look like. How do you convey the message of sharing music with friends? Initially, we loved the idea of using a 'lightning bolt' to emphasize the 'live' feature of the app. But it seemed too unoriginal in the sea of millions of apps out there. These are some of the options I came up with:`}</p>
    <undefined><div {...{
        "className": "blog-content-image-container"
      }}>{`
  `}<img parentName="div" {...{
          "src": "https://res.cloudinary.com/dzrqeyspq/image/upload/v1621782212/lisn-icons_mmezam.png",
          "className": "blog-content-image",
          "style": {
            "maxWidth": "100%",
            "height": "300px",
            "objectFit": "contain"
          },
          "alt": "A few branding options I explored"
        }}></img>
      </div>
      <p {...{
        "className": "image-caption"
      }}>{`My initial explorations`}</p></undefined>
    <undefined><div {...{
        "className": "blog-content-image-container"
      }}>{`
  `}<img parentName="div" {...{
          "src": "https://res.cloudinary.com/dzrqeyspq/image/upload/v1621782213/lisn-icon_bob3oj.png",
          "className": "blog-content-image",
          "alt": "The final logo"
        }}></img>
      </div>
      <p {...{
        "className": "image-caption"
      }}>{`Our final pick`}</p></undefined>
    <h4>{`Post launch improvements`}</h4>
    <p>{`After thoroughly studying user behaviour over time, we realized that the app needed a better library as well as a separate solo listening experience. How do we become our user's primary app, rather than Spotify or Soundcloud? The answer was to bring their libraries and make their solo listening as easy as the other apps. Our users could now listen to their usual music but in Lisn instead of Spotify/Soundcloud, thus making it much easier to share it with their friends from inside the app.`}</p>
    <p>{`Here's what the new library looked like`}</p>
    <div className="blog-content-image-container">
  <video className="blog-content-video" playsInline webkitPlaysinline="" poster="https://thumbs.gfycat.com/JauntyWellwornCony-poster.jpg" autoPlay loop muted height="600px">
    <source id="webmSource" src="https://fat.gfycat.com/JauntyWellwornCony.webm" type="video/webm" />
    <source id="mp4Source" src="https://fat.gfycat.com/JauntyWellwornCony.mp4" type="video/mp4" />
  </video>
    </div>
    <p className="image-caption">What was shipped</p>
    <h4>{`Impact 🍪`}</h4>
    <p>{`A new library and a new sharing experience lead to a ⬆️ `}<strong parentName="p">{`10%`}</strong>{` increase in songs shared!`}</p>
    <h4>{`Lisn was shut down in late 2017`}</h4>
    <p>{`After a roller coaster of emotions and loads of work, we decided to shut down Lisn. You can read more `}<a parentName="p" {...{
        "href": "https://medium.com/@abhinavchhikara/were-shutting-down-lisn-84758dfce2c4"
      }}>{`here`}</a>{` and `}<a parentName="p" {...{
        "href": "https://www.techinasia.com/music-app-lisn-shutdown-insights-from-failed-startups"
      }}>{`here`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      