import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "slug": "/blog/omio-class-fare",
  "date": "2019-10-05",
  "title": "A framework for class and fare selection",
  "isComplete": "true",
  "subtitle": "Senior Product Designer, Omio 2019",
  "gradient": "linear-gradient(180deg, #BFD3EB 0%, #5E90CC 100%)",
  "featuredImage": "omio-tcp-snapshot.png",
  "images": ["omio-tcp-1.png", "omio-tcp-2.png", "omio-tcp-3.png"]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Omio sells tickets for buses, trains, flights, and ferries too. Every transport mode has a different mental model with which travellers approach buying these tickets. And every vendor sells tickets differently for their respective transport mode. Omio tries to fix these problems by offering users a consistent ticket buying experience whether it be a bus, train or flight.`}</p>
    <h6>{`The problem`}</h6>
    <p>{`Omio works with different travel providers. Each provider has a distinct and complex fare/class system. All these fares and classes are interdependant e.g. a refundable fare may only be available in first class. This makes it extremely confusing for the user. The user’s needs are not being met. Omio’s conversion for fare upgrades was almost 50% lower than other products on the market`}</p>
    <h6>{`What the design looked like before`}</h6>
    <p>{`In an earlier iteration of Omio the class and fare options were hidden behind a button. This led to a few problems:`}</p>
    <ul>
      <li parentName="ul">{`Users didn’t know what their options were because the button was easy to miss`}</li>
      <li parentName="ul">{`Even when the user would tap the button to see all the options, they would find it confusing`}</li>
      <li parentName="ul">{`For the transport company, Omio’s class and fare upgrade conversions were much lower than the industry standard`}</li>
    </ul>
    <undefined><div {...{
        "className": "blog-content-image-container"
      }}>{`
  `}<img parentName="div" {...{
          "src": "https://res.cloudinary.com/dzrqeyspq/image/upload/v1621782213/omio-tcp-1_lqmfg4.png",
          "className": "blog-content-image",
          "alt": "What the class and fare page looked like before"
        }}></img>
      </div>
      <p {...{
        "className": "image-caption"
      }}>{`
  Everything is hidden behind the 'Change class and fare' button
`}</p></undefined>
    <undefined><div {...{
        "className": "blog-content-image-container"
      }}>{`
  `}<img parentName="div" {...{
          "src": "https://res.cloudinary.com/dzrqeyspq/image/upload/v1621782213/omio-tcp-2_efed3p.png",
          "className": "blog-content-image",
          "alt": "What the class and fare page looked like before"
        }}></img>
      </div>
      <p {...{
        "className": "image-caption"
      }}>{`
  All the fares are just listed out without an easy way to distinguish between
  them
`}</p></undefined>
    <h4>{`Exploring possible solutions 🔮`}</h4>
    <p>{`I came up two hypotheses:`}</p>
    <ul>
      <li parentName="ul">{`The user doesn't need to see all the options simultaneously as this is overwhelming`}</li>
      <li parentName="ul">{`A consistent naming system was required that could clearly categorise the classes and fares of multiple transport options into a few broad categories e.g. flexibility, comfort`}</li>
    </ul>
    <p>{`Based on these assumptions, I started exploring some options that showed the class and fare options more clearly.`}</p>
    <undefined><div {...{
        "className": "blog-content-image-container"
      }}>{`
  `}<img parentName="div" {...{
          "src": "https://res.cloudinary.com/dzrqeyspq/image/upload/v1621782344/omio-tcp-3_use8gg.png",
          "className": "blog-content-image",
          "style": {
            "maxWidth": "100%",
            "maxHeight": "580px"
          },
          "alt": "Early wireframes"
        }}></img>
      </div>
      <p {...{
        "className": "image-caption"
      }}>{`
  Making the class and fare options visible at a glance{" "}
`}</p></undefined>
    <div className="blog-content-image-container">
  <video className="blog-content-video" id="video-annualpowerlessamericanavocet" alt="AccordionBCP GIF" height="480px" autoPlay playsInline preload="auto" poster="https://thumbs.gfycat.com/AnnualPowerlessAmericanavocet-mobile.jpg" tabIndex="-1" muted loop>
    <source src="https://giant.gfycat.com/AnnualPowerlessAmericanavocet.mp4" type="video/mp4" />
    <source src="https://thumbs.gfycat.com/AnnualPowerlessAmericanavocet-mobile.mp4" type="video/mp4" />
  </video>
    </div>
    <p className="image-caption">An early prototype of my proposed solution</p>
    <p>{`In my proposed solution I decided to balance the number of choices the user has with the number of choices visible on the screen at a glance. The best solution was achieved by ensuring that the correct information was accessible at the most pertinent time.`}</p>
    <h4>{`What was shipped 🚀`}</h4>
    <div className="blog-content-image-container">
  <video className="blog-content-video" id="video-consideratequarrelsomegreendarnerdragonfly" alt="Shipped Mobile" height="600px" autoPlay playsInline preload="auto" poster="https://thumbs.gfycat.com/ConsiderateQuarrelsomeGreendarnerdragonfly-mobile.jpg" tabIndex="-1" muted loop>
    <source src="https://giant.gfycat.com/ConsiderateQuarrelsomeGreendarnerdragonfly.mp4" type="video/mp4" />
    <source src="https://thumbs.gfycat.com/ConsiderateQuarrelsomeGreendarnerdragonfly-mobile.mp4" type="video/mp4" />
  </video>
    </div>
    <video className="blog-content-video" id="video-insignificantdistantaffenpinscher" alt="Shipped Desktop" width="100%" autoPlay playsInline preload="auto" poster="https://thumbs.gfycat.com/InsignificantDistantAffenpinscher-mobile.jpg" tabIndex="-1" muted loop>
  <source src="https://giant.gfycat.com/InsignificantDistantAffenpinscher.mp4" type="video/mp4" />
  <source src="https://thumbs.gfycat.com/InsignificantDistantAffenpinscher-mobile.mp4" type="video/mp4" />
    </video>
    <h4>{`Impact 🍪`}</h4>
    <p>{`A few months after this had been shipped and significant data had been collected, I was happy to see that we had a ⬆️ `}<strong parentName="p">{`15%`}</strong>{` increase in class and fare upgrades!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      