import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "slug": "/blog/sennder",
  "date": "2020-10-05",
  "title": "Designing a modern logistics experience for today",
  "isComplete": "true",
  "subtitle": "Senior Product Designer, sennder 2021",
  "gradient": "linear-gradient(180deg, #FFDEBC 0%, #FF8300 100%)",
  "featuredImage": "sennder-snapshot.png"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`As of 2021, sennder had more than 800 employees, but only 5 designers. This added a lot of responsibility on each of our shoulders, and we took it head on 💪🏽.`}</p>
    <p>{`I’ll try to cover some of my major impact areas while I was working at sennder between 2020 and 2021.`}</p>
    <h6>{`How do we modernise an age old industry?`}</h6>
    <p>{`When I joined sennder I found out 2 things, the logistics industry is MASSIVE and it is an industry that hasn’t caught up to the working styles and efficiencies of today. This created a lot of opportunities for us to make changes that might impact a whole industry.`}</p>
    <h6>{`How can we make logistics teams plan their business better?`}</h6>
    <p>{`Our goal at sennder was to make our platform ‘orcas’ the go-to for anything carriers need to run their business. One of the core aspects of running a logistics business is to plan trips and efficiently utilize the fleet at hand. We conducted user research on how carriers plan orders, and found some interesting insights,`}</p>
    <ul>
      <li parentName="ul">{`Most carriers have an excel sheet that may be based on different time intervals which varies carrier to carrier, some like to plan monthly, some weekly. Moreover, each carrier’s sheet is slightly different in their own way.`}</li>
      <li parentName="ul">{`The familiarity of a tool like Excel is hard to beat.`}</li>
      <li parentName="ul">{`Because most of the work being done is manual, it is prone to errors.`}</li>
    </ul>
    <p>{`What we realised is that even though excel is familiar, it is not a tool that is intrinsically smart. It is not a tool designed from the ground up to tackle fleet management. We came up with a planner that does exactly that.`}</p>
    <div {...{
      "className": "blog-content-image-container"
    }}>{`
  `}<img parentName="div" {...{
        "src": "https://res.cloudinary.com/dzrqeyspq/image/upload/v1648467162/Sennder_Planner_wmoick.png",
        "className": "blog-content-image blog-image-withshadow",
        "alt": "The sennder planner"
      }}></img>
    </div>
    <div {...{
      "className": "blog-content-image-container"
    }}>{`
  `}<img parentName="div" {...{
        "src": "https://res.cloudinary.com/dzrqeyspq/image/upload/v1648467161/Sennder_Planner_Details_loa4bt.png",
        "className": "blog-content-image blog-image-withshadow",
        "alt": "The sennder planner explained"
      }}></img>
    </div>
    <video className="blog-content-video" id="video-confusedevenbaiji" alt="Sennder order drag GIF" width="100%" autoPlay="true" playsInline="true" preload="auto" poster="https://thumbs.gfycat.com/YearlyFluffyFalcon-mobile.jpg" tabIndex="-1" muted="true" loop="true">
  <source src="https://giant.gfycat.com/YearlyFluffyFalcon.mp4" type="video/mp4" />
  <source src="https://thumbs.gfycat.com/YearlyFluffyFalcon-mobile.mp4" type="video/mp4" />
    </video>
    <p className="image-caption">
  Smartly auto assigning orders for our carriers could potentially save them
  hours of busy work.
    </p>
    <undefined><div {...{
        "className": "blog-content-image-container"
      }}>{`
  `}<img parentName="div" {...{
          "src": "https://res.cloudinary.com/dzrqeyspq/image/upload/v1648486824/Anatomy_of_a_trip_pqocya.png",
          "className": "blog-content-image blog-image-withshadow",
          "alt": "Anatomy of a trip"
        }}></img>
      </div>
      <p {...{
        "className": "image-caption"
      }}>{`
  All the critical information about a trip was visible at a glance
`}</p></undefined>
    <h6>{`How can we make planning less error prone?`}</h6>
    <p>{`Because we knew that a trip can only be taken at certain periods of time, when a user tried to make changes, we helped them avoid errors by blocking certain areas of the planner. This made things even better than their existing system.`}</p>
    <video className="blog-content-video" id="video-confusedevenbaiji" alt="Sennder order drag GIF" width="100%" autoPlay="true" playsInline="true" preload="auto" poster="https://thumbs.gfycat.com/ConfusedEvenBaiji-mobile.jpg" tabIndex="-1" muted="true" loop="true">
  <source src="https://giant.gfycat.com/ConfusedEvenBaiji.mp4" type="video/mp4" />
  <source src="https://thumbs.gfycat.com/ConfusedEvenBaiji-mobile.mp4" type="video/mp4" />
    </video>
    <p className="image-caption">
  We would prevent the carrier from dragging trips to the wrong side of the
  planner.
    </p>
    <h6>{`This is a place that we call home 🏠`}</h6>
    <p>{`While working on a product that has multiple workflows, we wanted to understand what a ‘home’ page would look like. Now what even is a home screen? We realised that we wanted our users to be able to see information about their whole business at a glance. This led me to build a ‘Dashboard’ as our home page. I approached it by designing a ‘widget’ system that can be adopted by any team in our organisation. This way, each team can ship a widget based on their focus area.`}</p>
    <div {...{
      "className": "blog-content-image-container"
    }}>{`
  `}<img parentName="div" {...{
        "src": "https://res.cloudinary.com/dzrqeyspq/image/upload/v1648486635/Sennder_-_Dashboard_lppesk.png",
        "className": "blog-content-image blog-image-withshadow",
        "alt": "Sennder dashboard"
      }}></img>
    </div>
    <undefined><div {...{
        "className": "blog-content-image-container"
      }}>{`
  `}<img parentName="div" {...{
          "src": "https://res.cloudinary.com/dzrqeyspq/image/upload/v1648486635/Sennder_-_Dashboard_org_ss7hlz.png",
          "className": "blog-content-image blog-image-withshadow",
          "alt": "Sennder dashboard with org chart"
        }}></img>
      </div>
      <p {...{
        "className": "image-caption"
      }}>{`
  Each widget on the Dashboard was owned by a different Pod while still being
  coherent
`}</p></undefined>
    <h6>{`Impact`}</h6>
    <p>{`Currently sennder is one of the biggest providers of a marketplace and end to end carrier management solutions for land transport in Europe. My contributions to the product are being used by over `}<strong parentName="p">{`6000`}</strong>{` Carriers all over Europe in 8 different languages.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      